import { appConfig } from '../constants/appConfig'

export const getDepartmentListPropsByCandidate = (candidate = {}) => ({
  address: candidate.address || `г ${candidate.city}`,
  withSearch: true
})

export const getScheduleProps = ({ footer = null } = {}) => ({
  hideHeader: true,
  title: 'Выберите дату и время собеседования',
  footer
})

export const isActionSameState = (actionName, state) => {
  const actions = appConfig.workflows.candidate.actions
  const states = appConfig.statuses.values
  return (
    (actionName === actions.scheduleInterview && state === states.interviewScheduled) ||
    state === states[actionName]
  )
}

export const getDisabledRolesForUser = (roles, agencyId) => {
  let disabledRoles = []
  switch (true) {
    case roles?.includes(appConfig.roles.recruiter):
      disabledRoles = [
        appConfig.roles.manager,
        appConfig.roles.admin,
        appConfig.roles.hiringManager,
        appConfig.roles.TU,
        appConfig.roles.operationDirector
      ]
      if (agencyId) {
        disabledRoles.push(appConfig.roles.prioritySelector)
      }
      break

    case roles?.includes(appConfig.roles.hiringManager):
      disabledRoles = [
        appConfig.roles.recruiter,
        appConfig.roles.prioritySelector,
        appConfig.roles.manager,
        appConfig.roles.admin,
        appConfig.roles.TU,
        appConfig.roles.operationDirector
      ]
      break

    case roles?.includes(appConfig.roles.TU):
      disabledRoles = [
        appConfig.roles.recruiter,
        appConfig.roles.hiringManager,
        appConfig.roles.operationDirector
      ]
      break

    case roles?.includes(appConfig.roles.operationDirector):
      disabledRoles = [appConfig.roles.recruiter, appConfig.roles.hiringManager, appConfig.roles.TU]
      break

    case roles?.includes(appConfig.roles.manager):
    case roles?.includes(appConfig.roles.admin):
      disabledRoles = [appConfig.roles.recruiter, appConfig.roles.hiringManager]
      break

    case roles?.includes(appConfig.roles.prioritySelector):
      disabledRoles = [appConfig.roles.hiringManager]
      break
  }
  return disabledRoles
}
